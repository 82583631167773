.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.full-container {
  height: 100dvh;
  background: lightgray;
  display: flex;
  flex-flow: column;
  justify-content: top;
  align-items: center;
}

.footer {
  background: gray;
  width: 100%;
  display: flex;
  flex-flow: column;
  /* justify-content: center; */
  align-items: center;
  flex: 1;
  z-index: 1;
  max-height: 10dvh;
  overflow: auto !important;
  -webkit-overflow-scrolling: touch;
}



.container {
  display: grid;
  resize: both;
  min-width: 50px;
  min-height: 50px;
  width: 100%;
  height: 90dvh;
  background: green
}

.embed {
  width: 100%;
  aspect-ratio: 2/3;
  object-fit: contain;
  box-sizing: border-box;
  display: flex;
  margin: auto;
  background-color: purple;
  position: relative
}

.full-container1 {
  height: 100dvh;
  background: gray;
  /* display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center; */
}

.container1 {
  display: grid;
  resize: both;
  width: 100%;
  height: 100%;
  background: green
}

.embed1 {
  background-color: purple;
  width: auto;
  height: 100%;

}

/* @container resize-box (aspect-ratio > 2/3) {
  .embed {
    width: auto;
    height: 100%;
  }
} */



.outer-container {
  flex: 1;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: purple;
  width: 100%
}

.aspect-ratio-container {
  background: green;
}


.grid-container {
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  grid-auto-rows: 1fr;
  /* Equal cell height for all rows */
  /* grid-template-rows: auto; */
  /* Set rows to automatically adjust based on content */
  gap: 10px;
  /* max-width: calc(90vh * 16 / 9); */
  /* Set max-width based on desired aspect ratio */

  /* Set max-height to control the overall height */
  /* overflow: hidden; */
  /* Hide overflow content */
  /* aspect-ratio: 9 / 21; */
  /* Set the aspect ratio to 9:16 */
  /* background-color: #61dafb; */
  /* width: 100vw;
  height: 90vh */
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
  position: absolute;
}

.image-wrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
  /* border: 1px solid black; */
  user-select: none;
  margin: -1px
}

.image-wrapper img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  display: block;
  object-fit: contain;
}

.drag-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: grab;
}

.box-overlay {
  /* border: 1px dotted gray; */
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

}


button {
  width: 200px;
  height: 50px;
}

#intro-form #rows,
#intro-form #cols {
  width: 3em;
}

#intro-form #aspectW,
#intro-form #aspectH {
  width: 4em;
}

#intro-form label {
  padding-right: 0.5em;
}